import React, { useState, useEffect } from "react";
import EventsCard from "./EventsCard";
import imageSrc from "../../assets/images/placeholder-card.png";
import moment from "moment/moment";
import "moment/locale/pt-br";
import { loadAgenda } from "../../services/googleAgendaService";
import NoEventsLayout from "./NoEventsLayout";

function Loader() {
  return(
    <div>Carregando...</div>
  )
}

function Events() {
  let [dataCard, setDataCard] = useState("");
  let [dataEvent, setDataEvent] = useState(null);
  let [isLoading, setLoading] = useState(false);
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        //Get Events
        const eventsCalendar = await loadAgenda();

        if ([200, 304].includes(eventsCalendar.status)) {
          setLoading(false);
          // Events
          const events = eventsCalendar.result.items;
          const pathImage = "https://drive.google.com/uc?id="
          let eventsList = events.map((event) => {
            return {
              image: event.attachments ?  pathImage + event.attachments[0].fileId : [],
              day: moment(event.start.dateTime).format("D"),
              mounth: moment(event.start.dateTime).format("MMM"),
              title: event.summary,
              description: event.description,
              hour:
                moment(event.start.dateTime).format("HH[H]") +
                " - " +
                moment(event.end.dateTime).format("HH[H]"),
              fullDate:  moment(event.start.dateTime).format("LL"),
              dayWeek: moment(event.start.dateTime).format("dddd"),
            };
          });

          setDataEvent(eventsList);

          // Filling in CardEvent
          const lastEvent = events[0];
          const image = lastEvent?.attachments ? pathImage + lastEvent.attachments[0].fileId : [];
          const eventWeekDay = moment(lastEvent.start.dateTime).format("dddd");
          const eventDate = moment(lastEvent.start.dateTime).format("LL");
          const startHour = moment(lastEvent.start.dateTime).format("HH[H]");
          const endHour = moment(lastEvent.end.dateTime).format("HH[H]");
          const title = lastEvent.summary;
          const description = lastEvent.description;

          setDataCard({
            image: image,
            fullDate: eventDate,
            dayWeek: eventWeekDay,
            title: title,
            description: description,
            hour: startHour + " - " + endHour,
          });
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    })();
  }, []);

  /**
   * Getting dataEvent with click
   * @param {*} data 
   */
  function handleClick(data) {
    const dataEventClick = JSON.parse(data.getAttribute('data-event'));
    setDataCard(dataEventClick)
  }

  return (
    <section id="calendar-events" className="calendar-events container reveal">
      <h2 className="calendar-events_title">AGENDA</h2>

      {isLoading && <Loader />}

      {!isLoading && !dataEvent && dataEvent?.length && 
        <NoEventsLayout />        
      }

      {!isLoading && dataEvent && dataEvent?.length > 0 && (
        <>
          <div className="calendar-events_cards">
            {dataEvent &&
              dataEvent.map((event) => {
                return (
                  <EventsCard
                    key={event.title}
                    day={event.day}
                    mounth={event.mounth}
                    title={event.title}
                    description={event.description}
                    hour={event.hour}
                    dataEvent={event}
                    handleClick={handleClick}
                  />
                );
              })}
          </div>

          <div className="calendar-events_viewed">
            <div className="calendar-events_viewed_img">
              <img
                src={dataCard?.image?.length ? dataCard.image : imageSrc}
                alt="Imagem Evento"
                title="Imagem Evento"
                loading="lazy"
              />
            </div>

            <div className="calendar-events_viewed_content">
              <h3>{dataCard.title} </h3>
              <p className="icon icon-bell">
                {dataCard.dayWeek}, {dataCard.fullDate}
              </p>
              <p className="icon icon-smile">{dataCard.description || 'Em breve mais informações...'}</p>
              <p className="icon icon-clock">{dataCard.hour}</p>
            </div>
          </div>
        </>
      )}
    </section>
  );
}

export default Events;
