import React from "react";

function EventsCard({ day, mounth, title, description, hour, dataEvent, handleClick }) {
  return (
    <article className="events_card" data-event={JSON.stringify(dataEvent)} onClick={(e) => handleClick(e.currentTarget)}>
      <div className="events_card_date">
        <span className="events_card_date_day">{day}</span>
        <span>{mounth}</span>
      </div>

      <div className="events_card_content">
        <h3>{title}</h3>
        <p className="icon icon-smile">{description || 'Em breve mais informações...'}</p>
        <p className="icon icon-clock">{hour}</p>
      </div>
    </article>
  );
}

export default EventsCard;
